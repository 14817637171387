import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import moment from 'moment';
import { generateRecipeContent } from '../../utils/indications/indications-print-file';

const UserRecipeDetailsModal = ({
  isOpen,
  handleChangeIsOpen,
  monitorItem,
}) => {
  const patientData = monitorItem.dataPatient[0];
  const doctorData = monitorItem.dataDoctor[0];
  const indications = monitorItem?.indications ?? [];

  const htmlContent = generateRecipeContent(
    patientData,
    doctorData,
    monitorItem,
    indications
  );

  const handleDownloadRecipe = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.writeln(htmlContent);
    printWindow.document.close();

    const img = printWindow.document.querySelector('img');

    img.onload = () => {
      printWindow.print();
    };

    if (img.complete) {
      printWindow.print();
    }
  };

  return (
    <Modal
      show={isOpen}
      centered
      onHide={handleChangeIsOpen}
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: '#041689',
          color: 'white',
          borderBottom: '2px solid white',
          borderRadius: '10px 10px 0 0',
        }}
      >
        <Modal.Title style={{ fontWeight: 'bold', fontSize: '20px' }}>
          Receta Médica
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          textAlign: 'center',
          backgroundColor: '#f4f4f4',
          padding: '20px',
          borderRadius: '0 0 10px 10px',
        }}
      >
        <div
          style={{
            marginBottom: '15px',
            color: '#041689',
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          <p>
            <strong>Paciente:</strong>
            {`${patientData?.firstName} ${patientData?.lastName}` ||
              'No disponible'}
          </p>
          <p>
            <strong>Doctor:</strong>{' '}
            {`${doctorData?.firstName} ${doctorData?.lastName}` ||
              'No disponible'}
          </p>
          <p>{moment(monitorItem?.createdAt).format('DD/MM/YYYY')}</p>
        </div>

        <div style={{ marginTop: '10px' }}>
          <img
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              border: '3px solid #041689',
              objectFit: 'cover',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            }}
            src={
              monitorItem?.profile?.image
                ? `/api/profile/image/${monitorItem?.dataPatient[0]?.image}`
                : patientData?.gender === 'female'
                ? require('../../../assets/img/avatars/avatar-pat-female.png')
                : require('../../../assets/img/avatars/avatar-doc-male.png')
            }
            alt='profile'
          />
        </div>

        {indications.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            <p
              style={{
                color: '#041689',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              <strong>Indicaciones:</strong>
            </p>
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                fontSize: '14px',
                color: '#333',
              }}
            >
              {indications.map((indication, index) => (
                <li
                  key={index}
                  style={{
                    background: '#e9ecef',
                    marginBottom: '8px',
                    padding: '8px',
                    borderRadius: '6px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <strong>{indication.medicine}</strong> - {indication.dose},{' '}
                  {indication.frequency}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: '#f4f4f4',
        }}
      >
        <Button
          onClick={handleDownloadRecipe}
          style={{
            backgroundColor: '#041689',
            color: 'white',
            fontSize: '13px',
            fontWeight: '500',
            border: 'none',
            borderRadius: '5px',
            padding: '10px 20px',
            cursor: 'pointer',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#03387a')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#041689')}
        >
          Descargar Receta
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserRecipeDetailsModal;
