import moment from 'moment';

export function generateRecipeContent(
  patientData,
  doctorData,
  monitorItem,
  indications
) {
  const recipeContent = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 40px;
              background-color: #f8f9fa;
            }
            .recipe-header {
              text-align: center;
              font-size: 20px;
              margin-bottom: 20px;
              font-weight: bold;
              color: #041689;
            }
            .recipe-header img {
              width: 120px;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            table, th, td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #041689; /* Color primario */
              color: #ffeb01; /* Color secundario */
              font-weight: bold;
            }
            td {
              background-color: #ffffff;
            }
            .footer {
              text-align: center;
              font-size: 12px;
              margin-top: 30px;
              color: #6c757d;
            }
          </style>
        </head>
        <body>
          <div class="recipe-header">
            <img src="/img/KCL.png" alt="Logo kielsa clinica">
            <p><strong>Paciente:</strong> ${patientData.firstName} ${
    patientData.lastName
  }</p>
            <p><strong>Doctor:</strong> ${doctorData.firstName} ${
    doctorData.lastName
  }</p>
            <p><strong>Fecha:</strong> ${moment(monitorItem.createdAt).format(
              'DD/MM/YYYY'
            )}</p>
          </div>
  
          <table>
            <thead>
              <tr>
                <th>Medicamento</th>
                <th>Dosis</th>
                <th>Frecuencia</th>
              </tr>
            </thead>
            <tbody>
              ${indications
                .map(
                  (indication) => `
                <tr>
                  <td><strong>${indication.medicine}</strong></td>
                  <td>${indication.dose}</td>
                  <td>${indication.frequency}</td>
                </tr>
              `
                )
                .join('')}
            </tbody>
          </table>
  
          <div class="footer">
            <p>© ${new Date().getFullYear()} Kielsa clínica. Todos los derechos reservados.</p>
          </div>
        </body>
      </html>
    `;

  return recipeContent;
}
