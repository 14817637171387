/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';
import { AiOutlineWhatsApp, BiPhoneCall, BiVideo } from 'react-icons/all';
import Spinner from '../Spinner/Spinner';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import {
  addProfileDoctorCvFile,
  addProfileImage,
} from '../../redux/actions/profile.action';
import { openModalProfileImage } from '../../redux/actions/ui.action';
import {
  monitorConsults,
  addDoctor,
  getVideoconference,
  cancelConsultDoctorOffice,
  getPatientToReview,
} from '../../api/consult.doctor.office';
import { getDoctorOffice } from '../../api/doctor.office';
import {
  obseverChangeConsultDoctorOffice,
  changeConsultDoctorOffice,
} from '../../websokect/consultDoctorOfficeWebsocket';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import { searchPatientID } from '../../redux/actions/searchPatient.action';
import { useDispatch } from 'react-redux';
import UserRecipeDetailsModal from '../../query-monitor/modals/user-recipe-details';

const statusText = (status) => {
  const doc = {
    created: 'Creada',
    virtual: 'Virtual',
    attending: 'Atendiendo',
    finished: 'Finalizada',
  };
  return doc[status];
};

const NoneConsult = () => (
  <div
    style={{ height: '100vh' }}
    className='d-flex justify-content-center w-100'
  >
    <h2 className='m-auto text-black-50'>No estas en ningún consultorio.</h2>
  </div>
);

const QueryMonitor = ({ auth, history }) => {
  const [loading, setLoading] = useState(true);
  const [consult, setConsult] = useState(false);
  const [items, setItems] = useState([]);
  const { token, user } = auth;
  const [loadingConsult, setLoadingConsult] = useState(false);
  const [selectedMonitorItem, setSelectedMonitorItem] = useState(null);
  const [isOpenRecepeModal, setIsOpenRecepeModal] = useState(false);
  const dispatch = useDispatch();

  const handleOpenRecepeModal = (monitorItem) => {
    setSelectedMonitorItem(monitorItem);
    setIsOpenRecepeModal(true);
  };
  useEffect(() => {
    getDoctorOffice({ token })
      .then((res) => {
        const { data } = res;
        setConsult(data);
        monitorConsults({ _id: data._id, token })
          .then((res2) => {
            setItems(res2.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
        obseverChangeConsultDoctorOffice(data._id, () => {
          monitorConsults({ _id: data._id, token })
            .then((res2) => {
              setItems(res2.data);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [token]);

  if (loading) {
    return <Spinner />;
  }

  // validando que la enfermera posea una
  if (!consult) {
    return <NoneConsult />;
  }

  // funcion para crear la cita presencial
  const handleCreateConsultPresencial = (
    idConsultMonitor,
    consultationType,
    idPostVideoconference,
    bySchedule,
    followUpReference
  ) => {
    setLoadingConsult(true);
    addDoctor({
      token: token,
      idDoctor: user._id,
      status: 'attending',
      _id: idConsultMonitor,
      consultationType: consultationType,
      idPostVideoconference: idPostVideoconference,
      bySchedule,
      followUpReference,
    })
      .then((res) => {
        const { videoconference } = res;
        changeStatusDoctor({
          doctorId: user._id,
          role: user.role,
          status: 'occupied',
        });
        setLoadingConsult(false);
        history.push(
          `/dashboard/consulta-presencial-paciente/${videoconference._id}`
        );
      })
      .catch((error) => {
        setLoadingConsult(false);
        console.log(error);
      });
  };

  // funcion para ingresar a la videoconferencia
  const handleEnterConsultPresencial = (_id) => {
    getVideoconference({ token, _id })
      .then((res) => {
        const { videoconference } = res;
        changeStatusDoctor({
          doctorId: user._id,
          role: user.role,
          status: 'occupied',
        });
        history.push(
          `/dashboard/consulta-presencial-paciente/${videoconference._id}`
        );
      })
      .catch((err) => {
        console.log(err);
        alert(
          'Lo sentimos, no se pudo ingresar a la consulta. Contáctese con el administrador.'
        );
      });
  };

  //funcion entrar a la post consulta
  const handleEnterPostConsult = (_id, idPostVideoconference) => {
    changeStatusDoctor({
      doctorId: user._id,
      role: user.role,
      status: 'occupied',
    });

    history.push(`/dashboard/post-consulta/${idPostVideoconference}`);
  };

  const handleCancelConsult = (_id) => {
    Swal.fire({
      title: 'Escriba el motivo de la cancelación',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Si',
      showLoaderOnConfirm: true,
      cancelButtonText: 'No',
      preConfirm: (reasonCancel) =>
        cancelConsultDoctorOffice({
          token: token,
          reasonCancel: reasonCancel,
          _id: _id,
        })
          .then((res) => res)
          .catch((error) => {
            if (error.info) {
              Swal.showValidationMessage(error.info);
            } else {
              Swal.showValidationMessage('Error al cancelar la consulta');
            }
          }),
    }).then((result) => {
      if (result.isConfirmed) {
        changeConsultDoctorOffice({ idConsult: _id });
        window.location.href = '/dashboard';
      }
    });
  };

  const viewInfoPatient = (_id) => {
    try {
      if (_id) {
        getPatientToReview({ _id: _id, token }).then((res) => {
          const { value, profile, image, label, gender } = res;
          dispatch(
            searchPatientID(value, profile, image, label, gender, history)
          );
        });
      }
    } catch (err) {}
  };
  return (
    <div className='container-fluid'>
      <div className='user-dashboard'>
        {/* Web View */}
        <div className='d-none d-sm-block'>
          <h2 className='text-primary mb-5 mt-5'>
            Monitor&nbsp;
            <b className='text-secondarymdv'>de Consultas</b>
          </h2>
        </div>
        {/* Fin Web View */}
        {/* Mobile View */}
        <div className='d-block d-sm-none'>
          <h5 className='text-primary mb-4 mt-4 font-weight-bold text-center'>
            Monitor&nbsp;
            <b className='text-secondarymdv'>de Consultas</b>
          </h5>
        </div>
        {/* Fin Web View */}
        <div className='row'>
          <div className='col-12 col-md-12 col-sm-12 col-xs-12 gutter'>
            <div className='table-responsive'>
              <table className='table table-light table-borderless table-striped'>
                <thead className='font-weight-bold bg-primarymdv text-center text-uppercase'>
                  <tr>
                    <th scope='col' className='title-header-table'>
                      ID
                    </th>
                    <th scope='col' className='title-header-table'>
                      Fecha
                    </th>
                    <th scope='col' className='title-header-table'>
                      Hora
                    </th>
                    <th scope='col' className='title-header-table'>
                      Estado
                    </th>
                    <th scope='col' className='title-header-table'>
                      Paciente
                    </th>
                    <th scope='col' className='title-header-table'>
                      {user.role === 'nurse' ? ' Enfermera(o)' : 'Cajero(a)'}
                    </th>
                    <th scope='col' className='title-header-table'>
                      Doctor(a)
                    </th>
                    <th scope='col' className='title-header-table'>
                      Tipo cita
                    </th>
                    <th scope='col' className='title-header-table'>
                      Indicaciones
                    </th>
                    {(user.role === 'doctor' ||
                      user.role === 'nurse' ||
                      user.role === 'checker') && (
                      <th scope='col' className='title-header-table'>
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {items?.length === 0 ? (
                    <tr>
                      <th
                        colSpan={user.role === 'doctor' ? '8' : '7'}
                        scope='row'
                        className='text-secondarymdv'
                      >
                        No hay datos
                      </th>
                    </tr>
                  ) : (
                    items.map((item, index) => (
                      <>
                        <tr>
                          <th scope='row' className='text-secondarymdv'>
                            {index + 1}
                            {/* {item._id.slice(-4)} */}
                          </th>
                          <th scope='row' className='text-secondarymdv'>
                            {moment(item.createdAt).format('DD/MM/YYYY')}
                          </th>
                          <td>{item.hour}</td>
                          <td>{statusText(item.status)}</td>
                          <td>
                            {item.dataPatient &&
                              item.dataPatient[0] &&
                              `${item?.dataPatient?.[0]?.firstName} ${item?.dataPatient?.[0]?.lastName}`}
                            {item.dataPatient &&
                            item.dataPatient[0] &&
                            user.role === 'doctor' ? (
                              <button
                                type='button'
                                className='btn btn-info mt-2 btn-sm rounded-pill'
                                onClick={() =>
                                  viewInfoPatient(item?.dataPatient?.[0]?._id)
                                }
                                style={{ marginBottom: '4%', marginLeft: '4%' }}
                              >
                                Ver información
                              </button>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            {item.dataNurse &&
                              item.dataNurse[0] &&
                              `${item?.dataNurse?.[0]?.firstName} ${item?.dataNurse?.[0]?.lastName}`}
                          </td>
                          <td>
                            {item.dataDoctor &&
                              item.dataDoctor[0] &&
                              `${item.dataDoctor[0].firstName} ${item.dataDoctor[0].lastName}`}
                          </td>
                          <td>
                            {item.consultationType === 'presential' &&
                              'Presencial'}
                            {item.consultationType === 'lab' &&
                              'Servicio laboratorio'}
                            {item.consultationType === 'call' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <BiPhoneCall color='#011688' size={30} />
                                <span style={{ marginLeft: 5 }}>
                                  Llamada telefónica
                                </span>
                              </div>
                            )}
                            {item.consultationType === 'videocall' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <BiVideo color='#011688' size={30} />
                                <span style={{ marginLeft: 5 }}>
                                  Videollamada
                                </span>
                              </div>
                            )}
                            {item.consultationType === 'whatsapp' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <AiOutlineWhatsApp color='00BB2D' size={30} />
                                <span style={{ marginLeft: 5 }}>WhatsApp</span>
                              </div>
                            )}
                          </td>
                          {item?.indications?.length <= 0 ? (
                            <td>
                              <div>No hay indicaciones</div>
                            </td>
                          ) : (
                            <td>
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-info mt-2 btn-sm rounded-pill'
                                  onClick={() => handleOpenRecepeModal(item)}
                                >
                                  Descargar receta
                                </button>
                              </div>
                            </td>
                          )}

                          {(user.role === 'doctor' ||
                            user.role === 'nurse' ||
                            user.role === 'checker') && (
                            <th>
                              {loadingConsult ? (
                                <div>
                                  <BootstrapSpinner
                                    animation='border'
                                    variant='primary'
                                  />
                                  <h6>...cargando</h6>
                                </div>
                              ) : (
                                <>
                                  {item.status === 'created' &&
                                    user.role === 'doctor' && (
                                      <div className='d-flex'>
                                        <button
                                          onClick={() => {
                                            setLoadingConsult(true);
                                            handleCreateConsultPresencial(
                                              item._id,
                                              item.consultationType,
                                              item.idPostVideoconference,
                                              item.bySchedule,
                                              item.followUpReference
                                            );
                                          }}
                                          type='button'
                                          className='btn btn-primary m-auto btn-sm'
                                        >
                                          Iniciar consulta
                                        </button>
                                        <button
                                          onClick={() => {
                                            handleCancelConsult(item._id);
                                          }}
                                          type='button'
                                          className='btn btn-danger rounded-pill m-auto btn-sm'
                                        >
                                          Cancelar consulta
                                        </button>
                                      </div>
                                    )}
                                  {item.status === 'attending' &&
                                    item.idDoctor === user._id && (
                                      <button
                                        onClick={() => {
                                          handleEnterConsultPresencial(
                                            item._id
                                          );
                                        }}
                                        type='button'
                                        className='btn btn-primary btn-sm'
                                      >
                                        Ingresar a la consulta
                                      </button>
                                    )}
                                  {item.status === 'finished' &&
                                    item.idPostVideoconference && (
                                      <button
                                        onClick={() => {
                                          handleEnterPostConsult(
                                            item.dataDoctor._id,
                                            item.idPostVideoconference
                                          );
                                          /*history.push(
                                        `/dashboard/post-consulta/${item.idPostVideoconference}`
                                      )*/
                                        }}
                                        type='button'
                                        style={{ borderRadius: '0.8rem' }}
                                        className='btn btn-info btn-sm'
                                      >
                                        Post consulta
                                      </button>
                                    )}
                                </>
                              )}
                            </th>
                          )}
                        </tr>
                      </>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isOpenRecepeModal && selectedMonitorItem && (
        <UserRecipeDetailsModal
          isOpen={isOpenRecepeModal}
          monitorItem={selectedMonitorItem}
          handleChangeIsOpen={setIsOpenRecepeModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  addProfileDoctorCvFile,
  addProfileImage,
  openModalProfileImage,
})(QueryMonitor);
